<template>
  <div class="with-anchors">
    <h1 class="display-l">Case studies</h1>


    <section>
        <p>We use case studies to showcase the effectiveness of our solutions. Case studies are usually shorter than white papers and focus on measurable outcomes, like an increase in sales, visitors, or production hours.</p>
        <p>An InDesign(.indd) template has been prepared to help illustrate the content. </p>
    &nbsp;
    <p><img src="../../assets/img/case-studies.png" alt="" role="presentation" /></p>
        
    </section>

    <DownloadCTA title="Download Case Study template " 
                description="Files available in .indd format" 
                buttonTitle="Download .zip"
                link="/zip/Intelematics-templates_Case-Studies Folder.zip" />

  </div>
</template>

<script>
import DownloadCTA from "../common/DownloadCTA"

export default {
  name: "Case Studies",
  components: {
    DownloadCTA
  },
};
</script>
